<template>
  <section class="page-error">
    <div class="container text-center">
      <h1 class="error__title">
        {{ $t('error-title') }}
      </h1>

      <p class="error__body">
        {{ $t('error-body') }}
      </p>

      <div class="error__buttons">
        <TBtnLink class="text-black underline" to="/">
          {{ $t('back-to-homepage') }}
        </TBtnLink>

        <TBtnLink class="bg-primary hover:bg-primary-dark" to="/">
          {{ $t('get-free-valuation') }}
        </TBtnLink>
      </div>
    </div>
  </section>
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'

export default {
  name: 'ErrorPage',

  extends: AbstractPage,

  layout: 'content',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: undefined,
      path: '/error',
    }
  },

  /**
   */
  created() {
    this.headTitle = this.$t('title')
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Sorry! Er ging iets niet helemaal goed..",
    "error-title": "Sorry!",
    "error-body": "Er ging iets niet helemaal goed..",
    "back-to-homepage": "Terug naar de startpagina",
    "get-free-valuation": "Vraag nu een gratis waardebepaling aan"
  },
  "en-GB": {
    "title": "Sorry! Something didn’t quite go right..",
    "error-title": "Sorry!",
    "error-body": "Something didn’t quite go right..",
    "back-to-homepage": "Back to homepage",
    "get-free-valuation": "Get a free valuation now"
  }
}
</i18n>

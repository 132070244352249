<template>
  <div class="form-modal">
    <div class="form-modal__top">
      <nuxt-link to="/" class="form-modal__logo">
        <InlineSvg class="h-6" src="images/logo.svg" />
      </nuxt-link>
      <slot name="title">
        <h1 v-if="title" class="form-modal__title">
          {{ title }}
        </h1>
      </slot>

      <nuxt-link to="/" class="form-modal__close">
        <IconClose class="w-5 h-5" />
      </nuxt-link>
    </div>

    <div class="form-modal__progress">
      <slot name="progressbar" />
    </div>

    <div class="form-modal__wrapper">
      <div class="form-modal__body">
        <div class="pb-4">
          <slot name="body" />
        </div>
      </div>
    </div>

    <div class="form-modal__footer">
      <slot name="footer">
        <div class="footer__container">
          <t-btn
            v-if="showBackBtn"
            id="footerBackButton"
            class="footer__back-btn"
            @click="$router.back()"
          >
            {{ $t('global.back') }}
          </t-btn>
          <t-btn
            id="footerNextButton"
            class="footer__next-btn w-1/2"
            @click="$nuxt.$emit('submit')"
          >
            {{ nextBtnText || $t('global.continue') }}
          </t-btn>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import IconClose from 'chimera/all/themes/jupiter/images/icons/IconClose'

export default {
  name: 'FormModal',

  components: {
    IconClose,
    InlineSvg,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    showBackBtn: {
      type: Boolean,
      default: false,
    },

    nextBtnText: {
      type: String,
      default: '',
    },
  },
}
</script>

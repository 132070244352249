<template>
  <div class="flex flex-col h-full">
    <FormModal :title="$t('title')" show-back-btn>
      <template #progressbar>
        <div class="flex justify-center py-8">
          <div class="flex space-x-3">
            <span class="rounded block h-1 bg-primary w-12" />
            <span class="rounded block h-1 bg-primary w-12" />
            <span class="rounded block h-1 bg-primary w-12" />
            <span class="rounded block h-1 bg-primary w-12" />
          </div>
        </div>
      </template>

      <template #body>
        <ContactInformationFormStep
          ref="step"
          :progress-value="progressValue"
        />
        <span class="text-accent text-xs">
          * {{ $t('global.requiredFields') }}
        </span>
      </template>
    </FormModal>
  </div>
</template>

<script>
import FormModal from 'chimera/all/themes/jupiter/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import ContactInformationFormStep from '~/components/form/steps/contactInformation/ContactInformationFormStep'

export default {
  components: {
    ContactInformationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Je gegevens',
      path: '/offertes-aanvragen/je-gegevens',
      progressValue: 80,
      checkoutStep: 5,
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Jouw contactgegevens",
    "title-results": "We hebben 6 makelaars gevonden",
    "step-title": "Contactgegevens",
    "submitText": "Verstuur",
    "step-1": "Jouw adresgegevens",
    "step-2": "Contactgegevens"
  },
  "en-GB": {
    "title": "Your contact details",
    "title-results": "We found 6 experts",
    "step-title": "Your info",
    "submitText": "Send",
    "step-1": "About your property",
    "step-2": "About you"
  }
}
</i18n>

<template>
  <div class="page-thank-you">
    <div class="container">
      <h1 class="thank-you__title">
        {{ $t('title') }}
      </h1>
      <p class="thank-you__subtitle">
        {{ $t('subtitle') }}
      </p>
    </div>

    <div class="tips container">
      <h2 class="tips__title">
        {{ $t('tips-title') }}
      </h2>
      <ul class="tips__container">
        <div class="mt-12 md:mt-16 w-full flex flex-col md:flex-row gap-y-9">
          <div
            v-for="tip of tips"
            :key="tip.text"
            class="flex md:flex-col md:items-center md:w-1/3"
          >
            <div>
              <div
                class="flex items-center justify-center h-10 w-10 rounded-full bg-primary text-white"
              >
                <component :is="tip.icon" class="h-6 w-6" />
              </div>
            </div>
            <div class="md:pl-4">
              <p class="ml-3 leading-5 md:ml-0 md:mt-3 md:text-center">
                {{ $t(tip.text) }}
              </p>
            </div>
          </div>
        </div>
      </ul>
    </div>

    <div class="page-thank-you__cross-sell">
      <div class="cross-sell">
        <div class="cross-sell__wrapper container">
          <div class="cross-sell__text">
            <h2 class="cross-sell__title">
              {{ $t('cross-sell-title') }}
            </h2>

            <p class="cross-sell__body">
              {{ $t('cross-sell-body') }}
            </p>
          </div>

          <div class="cross-sell__image">
            <img src="images/huisprojecten.png" alt="Huisprojecten.nl" />
          </div>
        </div>
      </div>

      <div class="cross-sell__bottom">
        <div class="flex justify-start md:w-1/2">
          <TBtnLink
            id="linkToHuisprojecten"
            class="bg-primary hover:bg-primary-dark"
            href="https://www.huisprojecten.nl"
            target="_blank"
          >
            {{ $t('cross-sell-button') }}
          </TBtnLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Usps from 'chimera/all/themes/jupiter/components/Usps'
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import IconPhone from 'chimera/all/themes/jupiter/images/icons/IconPhone'
import IconMail from 'chimera/all/themes/jupiter/images/icons/IconMail'
import IconCheckCircle from 'chimera/all/themes/jupiter/images/icons/IconCheckCircle'

export default {
  components: {
    Usps,
  },

  extends: AbstractPage,

  layout: 'content',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Aanvraag afgerond',
      headDescription:
        'Vul je postcode in en vergelijk geheel gratis 6 offertes van top makelaars uit jouw regio. ✓Dé beste prijs voor jouw makelaar ✓Bespaar tot 40% ✓100% Gratis!',
      path: '/offertes-aanvragen/aanvraag-afgerond',
      tips: [
        {
          icon: IconPhone,
          text: 'tips1-text',
        },
        {
          icon: IconMail,
          text: 'tips2-text',
        },
        {
          icon: IconCheckCircle,
          text: 'tips3-text',
        },
      ],
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Dankjewel voor je aanvraag!",
    "subtitle": "De makelaars nemen zo snel mogelijk contact op met jou.",
    "tips-title": "Tips om even door te nemen",
    "tips1-text": "Zorg dat je je telefoon en agenda bij de hand hebt, want professionals kunnen je telefonisch benaderen.",
    "tips2-text": "Vergeet niet om je spamfolder van je e-mail te controleren.",
    "tips3-text": "Kijk niet alleen naar de prijs, maar vergelijk ook op kwaliteit, service en betrouwbaarheid.",
    "cross-sell-title": "Bespaar ook op de kosten van andere diensten",
    "cross-sell-body": "Ga nu naar Huisprojecten.nl en vind gratis en vrijblijvend de beste deals van meer dan 2.000 geselecteerde professionals.",
    "cross-sell-button": "Ga naar Huisprojecten.nl"
  },
  "en-GB": {
    "title":  "Thank you for your request!",
    "subtitle":  "The experts will contact you as soon as possible.",
    "tips-title":  "Tips moving forward:",
    "tips1-text":  "Make sure you have your phone at hand, because professionals can contact you by phone.",
    "tips2-text":  "Remember to check your spam folder on your email.",
    "tips3-text":  "Compare on quality, service and reliability of the experts, and take your time to choose the right professional.",
    "cross-sell-title": "Also save on the costs of other services",
    "cross-sell-body": "Go to Huisprojecten.nl now and find the best deals from more than 2,000 selected professionals for free and without obligation.",
    "cross-sell-button": "Go to Huisprojecten.nl"
  }
}
</i18n>

<template>
  <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m4 4h16c1.1 0 2 0.9 2 2v12c0 1.1-0.9 2-2 2h-16c-1.1 0-2-0.9-2-2v-12c0-1.1 0.9-2 2-2z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="m22 6-10 7-10-7"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMail',
}
</script>
